/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { useStyles } from '@/components/Imprint/styles';

const Policy = () => {
  const classes = useStyles();
  const { dataJson } = useStaticQuery(graphql`
    {
      dataJson {
        policy {
          description
          items {
            id
            texts
            title
          }
          title
        }
      }
    }
  `);
  const { title, description, items } = dataJson.policy;

  return (
    <div className={classes.container}>
      <p className={classes.title}>{title}</p>
      <p className={classes.text}>{description}</p>
      {items.map(({ id, title: name, texts }) => (
        <Fragment key={id}>
          <p className={`${classes.text} ${classes.policyTitle}`}>{`${id}. ${name}`}</p>
          {texts.map((item, index) => (
            <p key={index} className={`${classes.text} ${index === 0 && classes.textFirst}`}>
              {item}
            </p>
          ))}
        </Fragment>
      ))}
    </div>
  );
};

export default Policy;
